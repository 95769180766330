// @import url("/assets/fonts/libre-baskerville/libre-baskerville.css");

$gray-50: #FAFAFA;
$gray-100: #F4F4F5;
$gray-200: #E4E4E7;
$gray-300: #D4D4D8;
$gray-400: #A1A1AA;
$gray-500: #71717A;
$gray-600: #52525B;
$gray-700: #3F3F46;
$gray-800: #27272A;
$gray-900: #18181B;

$background-color: $gray-100;
$body-color: $gray-700;
$heading-color: $gray-900;

html {
	font-family: Inter
}

body {
	background: $gray-200;
	margin: 1rem 0;

	// More accessible typography
	line-height: 1.6;
	font-size: 1rem;
	color: $body-color;
}

div.content {
	// Center page and set a max width for easier reading
	max-width: 700px;
	margin: 0 auto;
	padding: 1rem;
	background: $gray-100;
}

@media screen and (max-width: 700px) {
	body {
		// Adds lower padding to body to allow over-scrolling.
		margin: 0;
		padding-bottom: 2rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
	line-height: 1.1;
	margin-bottom: 1rem;
	color: $heading-color;
	letter-spacing: -1px;
}

h1 {
	font-size: 2.2rem;
	border-bottom: 1px solid $gray-300;
}

h2 {
	font-size: 1.6rem;
	border-bottom: 1px solid $gray-200;
}

h3 {
	font-size: 1.3rem;
	font-style: italic;
}

p {
	margin-top: .5rem;
}

a {
	color: blue;
	text-decoration: underline;

	&:hover,
	&:focus {
		color: #0000ffa3;
		text-decoration: none;
	}
}


.note {
	background: #fef3c7;
	padding: 1rem;
	color: #241d01;
	border-radius: 4px;
}

header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-bottom: 2px solid #00000022;
	align-items: center;
	background: #065ab5;
	padding: .5rem 1rem;
	// margin: -1rem -1rem 1rem -1rem;
	color: white;
	margin-bottom: 1rem;

	a.invisible-link {
		color: inherit;
		text-decoration: inherit;

		&:hover,
		&:active {
			text-decoration: underline;
		}
	}

	span.header-title {
		font-weight: bold;
		font-size: 1.2rem;
		padding-top: 1px;

		// padding is invisible, but lets header wrap on small screens before text is unreadable
		padding-right: 1rem;
	}

	div {
		img {
			max-height: 50px;
		}

		nav a {
			color: white;

			&:hover,
			&:focus {
				color: #ffffffc5;
				text-decoration: none;
			}

			&:not(:last-of-type) {
				margin-right: .5rem;
			}
		}
	}
}

main {
	padding: 0 .5rem;
}

@media screen and (max-width: 700px) {
	main {
		padding: 0;
	}
}

footer {
	font-size: .8rem;
	border-top: 1px solid $gray-300;
	padding-top: 1rem;
	margin-top: 2rem;

	span {
		opacity: .7;
	}

	img {
		height: 20px;
		vertical-align: text-bottom;
	}
}

// Inline code, NOT full-width code blocks
code.language-plaintext {
	font-size: .9rem;
	background: #ededed;
	border: 1px solid #00000020;
	border-radius: 2px;
	padding: 2px 5px;
}

blockquote {
	margin: 0;
	padding: .5rem .8rem;
	background: $gray-200;
	border-left: 5px solid $gray-300;
	color: $gray-800;
	margin-bottom: 1rem;

	p {
		margin: 0;
	}
}
