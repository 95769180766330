.highlight table td {
	padding: 5px;
}

.highlight table pre {
	margin: 0;
}

.highlight {
	border-radius: 5px;
}

pre.highlight {
	padding: 1rem;
	tab-size: 4;
	line-height: 1.3;

	code {
		font-family: monospace;
		font-size: 0.9rem;
	}
}

.highlight .c,
.highlight .ch,
.highlight .cd,
.highlight .cpf {
	color: #939293;
	font-style: italic;
}

.highlight .cm {
	color: #939293;
	font-style: italic;
}

.highlight .c1 {
	color: #939293;
	font-style: italic;
}

.highlight .cp {
	color: #939293;
}

.highlight .cs {
	color: #939293;
	font-style: italic;
}

.highlight .err {
	color: #960050;
	background-color: #1e0010;
}

.highlight .gi {
	color: #ffffff;
	background-color: #324932;
}

.highlight .gd {
	color: #ffffff;
	background-color: #493131;
}

.highlight .ge {
	color: #000000;
	font-style: italic;
}

.highlight .gr {
	color: #aa0000;
}

.highlight .gt {
	color: #aa0000;
}

.highlight .gh {
	color: #999999;
}

.highlight .go {
	color: #888888;
}

.highlight .gp {
	color: #555555;
}

.highlight .gu {
	color: #aaaaaa;
}

.highlight .k,
.highlight .kv {
	color: #78dce8;

}

.highlight .kc {
	color: #78dce8;

}

.highlight .kd {
	color: #78dce8;

}

.highlight .kp {
	color: #78dce8;

}

.highlight .kr {
	color: #78dce8;

}

.highlight .kt {
	color: #78dce8;

}

.highlight .kn {
	color: #ff6188;

}

.highlight .ow {
	color: #ff6188;

}

.highlight .o {
	color: #ff6188;

}

.highlight .mf {
	color: #ab9df2;
}

.highlight .mh {
	color: #ab9df2;
}

.highlight .il {
	color: #ab9df2;
}

.highlight .mi {
	color: #ab9df2;
}

.highlight .mo {
	color: #ab9df2;
}

.highlight .m,
.highlight .mb,
.highlight .mx {
	color: #ab9df2;
}

.highlight .se {
	color: #ab9df2;
}

.highlight .sa {
	color: #78dce8;

}

.highlight .sb {
	color: #ffd866;
}

.highlight .sc {
	color: #ffd866;
}

.highlight .sd {
	color: #ffd866;
}

.highlight .s2 {
	color: #ffd866;
}

.highlight .sh {
	color: #ffd866;
}

.highlight .si {
	color: #ffd866;
}

.highlight .sx {
	color: #ffd866;
}

.highlight .sr {
	color: #ffd866;
}

.highlight .s1 {
	color: #ffd866;
}

.highlight .ss {
	color: #ffd866;
}

.highlight .s,
.highlight .dl {
	color: #ffd866;
}

.highlight .na {
	color: #a9dc76;
}

.highlight .nc {
	color: #a9dc76;

}

.highlight .nd {
	color: #a9dc76;

}

.highlight .ne {
	color: #a9dc76;

}

.highlight .nf,
.highlight .fm {
	color: #a9dc76;

}

.highlight .no {
	color: #78dce8;
}

.highlight .bp {
	color: #fcfcfa;
}

.highlight .nb {
	color: #fcfcfa;
}

.highlight .ni {
	color: #fcfcfa;
}

.highlight .nn {
	color: #fcfcfa;
}

.highlight .vc {
	color: #fcfcfa;
}

.highlight .vg {
	color: #fcfcfa;
}

.highlight .vi {
	color: #fcfcfa;
}

.highlight .nv,
.highlight .vm {
	color: #fcfcfa;
}

.highlight .w {
	color: #fcfcfa;
}

.highlight .nl {
	color: #fcfcfa;

}

.highlight .nt {
	color: #ff6188;
}

.highlight {
	color: #fcfcfa;
	background-color: #221f22;
}
